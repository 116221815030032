<template>
  <div class="ConseillerSalariesDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Espace conseiller</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->
            <b-modal ref="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la communication avec le serveur,
                veuillez vérifier réessayer</p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal id="getAttributedAppointmentsAPILoadingErrorModal" ref="getAttributedAppointmentsAPILoadingErrorModal" hide-footer
            title="Erreur lors du chargement des rendez-vous">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des rendez-vous.</p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('getAttributedAppointmentsAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="salariesLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <b-tabs v-model="tabsIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-primary">
              <b-tab>
                <template slot="title">
                  <span class="h4"> Salariés </span>
                </template>
                <strong> Vous trouverez ici la liste de tous les salariés qui vous sont attribués.
                  Cliquez sur le salarié pour accéder à son profil.
                </strong>
                <div class="row mt-2">
                  <div class="col-12">
                    <em> Par défaut, il vous est présenté la liste des salariés qui
                      sont en attente de validation. Cliquez sur le salarié pour accéder à son
                      interface de gestion</em>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-6 text-center align-self-center">
                    <b-form-group id="state-group" label="Choisir l'état du salarié" label-for="state-input">
                      <b-form-select
                        v-model="state"
                        id="state-input"
                        :options='stateArrayForm'>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-6 text-center align-self-center">
                    <b-button
                      block variant="outline-danger" class="mt-2"
                      @click='filterSalariesAttributedByState'>
                          Filtrer les salariés selon l'état
                    </b-button>
                  </div>
                </div>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light" hover
                  :fields="salariesRenderFields"
                  :items="salariesRender"
                  @row-clicked="salarieRowClicked"
                  >
                  <template v-slot:cell(birthdate)="data">
                    {{ $date(data.value).format("DD/MM/YYYY") }}
                  </template>
                </b-table>

                <b-button
                  v-if="getAllSalariesAttributedByStateUrl"
                  block variant="outline-primary" class="mt-2"
                  @click='getAllSalariesAttributedByState(true)'>
                    Voir plus
                </b-button>
              </b-tab>

              <b-tab >
                <template slot="title">
                    <span class="h4"> Rendez-vous </span>
                  </template>
                  <strong> Vous trouverez ici la liste de tous les rendez-vous qui vous sont attribués. Cliquez sur le rendez-vous pour accéder au profil du salarié
                  </strong>
                  <b-table
                    responsive class="align-middle text-center mt-4 cursor-table"
                    headVariant="light" hover
                    :fields="appointmentsRenderFields"
                    :items="appointmentsRender"
                    @row-clicked="appointmentRowClicked"
                    >
                    <template v-slot:cell(date)="data">
                      {{ $date(data.value).format("DD/MM/YYYY") }}
                    </template>
                  </b-table>
              </b-tab>
          </b-tabs>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import formTitleComponent from '@/components/formTitleComponent'

const apiConseillerConnected = new APIConseillerConnected()

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'ConseillerSalariesDashboard',
  components: {
    formTitleComponent,
    Loading,
  },
  data: function () {
    return {
      tabsIndex: 0,

      salaries: [],
      salariesLoading: false,
      salariesRender: [],
      salariesRenderFields: [
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "birthdate", label: "Date de naissance", tdClass: 'ui-helper-center' },
        { key: "employeur", label: "Employeur", tdClass: 'ui-helper-center' },
        { key: "state", label: "Statut", tdClass: 'ui-helper-center' },
      ],

      state: '2ONGOING',
      stateArrayForm: [
        { text: 'Créé par le salarié', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Profil refusée', value: '3REFUSED' },
        { text: 'Validé par le conseiller : bri en construction', value: '4VALIDATED' },
        { text: 'BRI mis à disposition', value: '5BRI' },
        { text: 'Rendez-vous pris par le salarié', value: '6RDV' },
      ],
      baseGetAllSalariesAttributedByStateUrl: apiBaseUrl + `/get-all-salaries-attributed-by-state/`,
      getAllSalariesAttributedByStateUrl: apiBaseUrl + `/get-all-salaries-attributed-by-state/`,

      appointments: [],
      appointmentsRender: [],
      appointmentsRenderFields: [
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "date", label: "Date du rendez-vous", tdClass: 'ui-helper-center', sortable: true},
        { key: "start", label: "Heure de début", tdClass: 'ui-helper-center' },
        { key: "end", label: "Heure de fin", tdClass: 'minWidthTd ui-helper-center' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.getAllSalariesAttributedByState(false)
    this.getAttributedAppointments()
  },
  watch: {
    salaries: function (newSalaries) {
      if (newSalaries.length == 0) {
        this.salariesRender = []
      }
      else {
        var final_array = [
          {
            'id': newSalaries[0].id,
            'first_name': newSalaries[0].user.first_name,
            'last_name': newSalaries[0].user.last_name,
            'username': newSalaries[0].user.username,
            'birthdate': newSalaries[0].birthdate,
            'professional_category': newSalaries[0].professional_category,
            'employeur': newSalaries[0].employeur,
            'state': this.stateRender(newSalaries[0].state),

            'etat_civil': {
              'nb_children': newSalaries[0].etat_civil.nb_children,
              'is_service_militaire': newSalaries[0].etat_civil.is_service_militaire,
              'is_activity_abroad': newSalaries[0].etat_civil.is_activity_abroad,
              'is_demande_rachat_trimestres': newSalaries[0].etat_civil.is_demande_rachat_trimestres,
              'has_been_updated': newSalaries[0].etat_civil.has_been_updated,
            },

          }
        ]
        for (var i = 1; i < newSalaries.length; i++) {
          final_array.push(
            {
              'id': newSalaries[i].id,
              'first_name': newSalaries[i].user.first_name,
              'last_name': newSalaries[i].user.last_name,
              'username': newSalaries[i].user.username,
              'birthdate': newSalaries[i].birthdate,
              'professional_category': newSalaries[i].professional_category,
              'employeur': newSalaries[i].employeur,
              'state': this.stateRender(newSalaries[i].state),

              'etat_civil': {
                'nb_children': newSalaries[i].etat_civil.nb_children,
                'is_service_militaire': newSalaries[i].etat_civil.is_service_militaire,
                'is_activity_abroad': newSalaries[i].etat_civil.is_activity_abroad,
                'is_demande_rachat_trimestres': newSalaries[i].etat_civil.is_demande_rachat_trimestres,
                'has_been_updated': newSalaries[i].etat_civil.has_been_updated,
              }

            }
          )
        }
        this.salariesRender = final_array
      }
    },
    appointments: function (newAppointments) {
      if (newAppointments.length == 0) {
        this.appointmentsRender = []
      }
      else {
        var final_array = [
          {
            'id': newAppointments[0].id,
            'first_name': newAppointments[0].salarie.user.first_name,
            'last_name': newAppointments[0].salarie.user.last_name,
            'username': newAppointments[0].salarie.user.username,
            'date': newAppointments[0].day,
            'start': newAppointments[0].start,
            'end': newAppointments[0].end,
            'salarie_id': newAppointments[0].salarie.id,
          }
        ]
        for (var i = 1; i < newAppointments.length; i++) {
          final_array.push(
            {
              'id': newAppointments[i].id,
              'first_name': newAppointments[i].salarie.user.first_name,
              'last_name': newAppointments[i].salarie.user.last_name,
              'username': newAppointments[i].salarie.user.username,
              'date': newAppointments[i].day,
              'start': newAppointments[i].start,
              'end': newAppointments[i].end,
              'salarie_id': newAppointments[i].salarie.id,
            }
          )
        }
        this.appointmentsRender = final_array
      }
    }
  },
  methods: {
    getAllSalariesAttributedByState (isNext) {
      this.salariesLoading = true
      var url = this.getAllSalariesAttributedByStateUrl + this.state
      if (isNext) {
        url = this.getAllSalariesAttributedByStateUrl
      }
      apiConseillerConnected.getAllSalariesAttributedByState(this.token, url)
      .then((result) => {
        this.salaries = this.salaries.concat(result.data.results)
        this.getAllSalariesAttributedByStateUrl = result.data.next
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.salariesLoading = false
      })
    },

    getAttributedAppointments () {
      apiConseillerConnected.getAttributedAppointments(this.token)
      .then((result) => {
        this.appointments = result.data
      })
      .catch(() => {
        this.$refs.getAttributedAppointmentsAPILoadingErrorModal.show()
      })
    },

    filterSalariesAttributedByState () {
      this.getAllSalariesAttributedByStateUrl = this.baseGetAllSalariesAttributedByStateUrl
      this.salaries = []
      this.getAllSalariesAttributedByState(false)
    },

    salarieRowClicked(item) {
      this.$router.push('/conseiller-dashboard/salarie/' + item['id'])
    },

    appointmentRowClicked(item) {
      this.$router.push('/conseiller-dashboard/salarie/' + item['salarie_id'])
    },

    stateRender(state) {
      switch (state) {
        case '1CREATED':
          return 'Créé'
        case '2ONGOING':
          return 'Profil à valider'
        case '3REFUSED':
          return 'Profil refusé'
        case '4VALIDATED':
          return 'Profil validé, BRI à charger'
        case '5BRI':
          return 'BRI chargé'
        case '6RDV':
          return 'Rendez-vous pris par le salarié'
      }
      return 'Erreur'
    },
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
